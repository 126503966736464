import { Component } from '@angular/core';
import { Catalog, GetAllTodayTicketsAction, Ticket, TicketPagedList } from 'wms-lib';
import * as _ from 'lodash';
import { AppMainComponent } from '../../../../../../src/app/app.main.component';
import { Store } from '@ngxs/store';
import { KpisComponent } from '../kpis.component';

@Component({
    selector: 'app-kpis-ticket',
    templateUrl: './kpis-ticket.component.html',
    styleUrls: ['./kpis-ticket.component.scss']
})
export class KpisTicketComponent extends KpisComponent {
    ticketsStatuses: { [id: number]: Catalog } = {};
    items: { [client_id: number]: { [status_id: number]: TicketPagedList } } = {};
    itemsByStatus: { [status_id: number]: Ticket[] } = {};
    rows = 15;
    first = 0;
    carrouselStarted = false;

    constructor(protected app: AppMainComponent, protected store: Store) {
        super(app, store);
        this.store.dispatch(new GetAllTodayTicketsAction());

        this.store.select(state => state.catalogsState.ticket_statuses).subscribe(data => {
            this.ticketsStatuses = data;  
        });

        this.store.select(state => state.helpDeskState.tickets).subscribe(data => {
            this.items = data;
            this.itemsByStatus = {};
            _.forEach(this.items, (client:any) => {
                _.forEach(client, (status:any) => {
                    this.itemsByStatus[status.status] =  this.itemsByStatus[status.status] || [];
                    this.itemsByStatus[status.status].push(..._.values(status.items));
                });
            });
            if (_.size(this.itemsByStatus) > 0 && !this.carrouselStarted) {
                this.carrouselStarted = true;
                this.startCarrousel();
            }
        });
    }

    get dataTable(): Ticket[] {
        const items: Ticket[] = [];
        _.forEach(this.items, (client) => {
            _.forEach(client, (status) => {
                items.push(..._.values(status.items));
            });
        });        
        return _.sortBy(items, (item) => {
            item.status_key === 'O' ? 1 : item.status_key === 'W' ? 2 : 3;
        });
    }

    ticketStatus(ticket: Ticket): { 'label': string, 'color': string } {
            switch (ticket.status_key) {
            case 'O':
                return { 'label': 'Open', 'color': '#D1FBEB' };
            case 'W':
                return { 'label': 'Working', 'color': '#FFFDD0' };
            case 'C':
                return { 'label': 'Closed', 'color': '#fbfafd' };
            default:
                return { 'label': 'Unknown', 'color': '#fbfafd' };
            }
      }

      startCarrousel() {
        setInterval(() => {
          if (this.isLastPage() === true) {
            this.resetPage();
          } else {
            this.nextPage();
          }
        }, 15000);
      }
    
      nextPage() {
        this.first = this.first + this.rows;
      }
    
      resetPage() {
        this.first = 0;
      }
    
      isLastPage(): boolean {
        return (this.first + this.rows) >= this.dataTable.length;
      }
    
      isFirstPage(): boolean {
        return this.first === 0;
      }


}

