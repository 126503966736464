import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { CanTakeAttachment } from '../../../..//shared/mixins/can-take-picture';
import { applyMixins } from 'wms-lib';
import { TicketAttachmentsBaseComponent } from './ticket-attachments.component.base';

@Component({
  selector: 'app-ticket-attachments',
  templateUrl: './ticket-attachments.component.html',
  styleUrls: ['./ticket-attachments.component.scss']
})
export class TicketAttachmentsComponent extends TicketAttachmentsBaseComponent {
    
  takeAttachmentImage: (image: File) => Promise<void>;

  constructor(store: Store) { 
    super(store);
  }

  

  uploadFiles(event,uploader) {
    this.ticket.removeNotPersistedAttachments();
    event.files.forEach(att => this.ticket.appendAttachmentFromFile(att));
    this.save().subscribe(() => uploader.clear());
  }

  openFile(src: string) {
    window.open(src);
  }
  

}



applyMixins(TicketAttachmentsComponent, [CanTakeAttachment]);