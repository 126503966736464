import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
	  Catalog,
	  Client,
	  GetTicketAction,
	  NotificationService,
	  SaveTicketAction,
	  SetActiveClientAction,
	  SetActiveTicketAction,
	  Ticket,
    User,
    GetCompanyUsersAction
} from 'wms-lib';
import * as _ from 'lodash';
import { StateListenerComponent } from '../../../../shared/components/scan-listener/state-listener.component';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';

@Injectable()
export class TicketFormBaseComponent extends StateListenerComponent implements OnInit, OnDestroy {
	  ticket: Ticket = new Ticket({ title: '', description: '' });
	  ticket_id: number;
	  ticketMetadata: any;
	  statuses: { [id: number]: Catalog };
    _users: User[];
    users$: Observable<User[]>;
	  active_client: Client;

	  constructor(
		    protected store: Store,
		    protected route: ActivatedRoute,
		    protected notificationService: NotificationService
	  ) {
		    super();
		    this.ticketMetadata = JSON.parse(JSON.stringify(require('../../../models/ticket_metadata.json')));
        this.users$ = this.store.select(state => state.helpDeskState.company_users);
	  }

    get users() {
        return this._users;
    }

	  get tickedPersisted() {
		    return this.ticket && this.ticket.id > 0;
	  }

	  ngOnInit() {
        this.store.dispatch(new GetCompanyUsersAction());
		    this.addSub(
			      this.store.select((state) => state.catalogsState.active_client).subscribe((client) => {
				        if (client) {
					          this.active_client = client;
					          if (!this.ticket['id']) {
						            this.ticket.client_id = client.id;
					          }
				        }
			      })
		    );
        this.addSub(this.users$.subscribe(data => this._users = this.filterUsersActive(_.values(data))));

		    // This is for when the component is loaded using the angular routing system
		    this.route.params.subscribe((params: Params) => {
			      if (+params['id'] > 0) {
				        this.ticket_id = +params['id'];
				        this.requestTicket(this.ticket_id);
			      }
		    });

		    this.addSub(
			      this.store.select((state) => state.catalogsState.ticket_statuses).subscribe((data) => {
				        this.statuses = data || {};
			      })
		    );

		    this.addSub(
			      combineLatest(
				        this.store.select((state) => state.catalogsState.active_client),
				        this.store.select((state) => state.helpDeskState.active_ticket)
			      ).subscribe(([ active_client, active_ticket ]) => {
				        if (active_ticket) this.ticket = active_ticket;

				        if (active_client && active_ticket && active_client.id !== active_ticket.client_id) {
					          this.store.dispatch(new SetActiveClientAction({ client: new Client(active_ticket.client) }));
				        }
			      })
		    );
	  }

	  protected requestTicket(id: number) {
		    this.store.dispatch(new GetTicketAction(id));
	  }

	  ngOnDestroy() {
		    super.ngOnDestroy();
		    this.clearTicket();
	  }

	  persist() {
		    return this.store.dispatch(new SaveTicketAction({ ticket: this.ticket }));
	  }

	  clearTicket() {
		    this.store.dispatch(new SetActiveTicketAction(null));
	  }

	  filterUsersActive(users: User[]): User[] {
		return users.filter((user) => user.active);
	  }
}
