import { Component, OnInit, NgZone, AfterViewInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotificationService, CodebarReaderService, ModalDialogService, Package, AuthenticationService, PartNumber } from 'wms-lib';
import { PackageComponent } from '../../../shared/components/base-components/package.component'
import { CanTakeDamageReport } from '../../../shared/mixins/can-take-picture'
import { Router } from '@angular/router';

@Component({
    selector: 'tr[app-arrival-package]',
    templateUrl: './arrival-package.component.html',
    styleUrls: ['../../../shared/components/base-components/package.component.scss']
})
export class ArrivalPackageComponent extends PackageComponent implements OnInit, CanTakeDamageReport, AfterViewInit {
    @Output() assignPartNumber: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected store: Store,
        public notification_service: NotificationService,
        protected codebar_service: CodebarReaderService,
        protected ng_zone: NgZone,
        protected modal_service: ModalDialogService,
        protected _router: Router,
        protected authenticationService : AuthenticationService,
        elementRef: ElementRef
    ) {
        super(store,notification_service,codebar_service,ng_zone,modal_service,_router,authenticationService);
        this.el = elementRef;
    }

    @Input()
    set package(p: Package) {
        this._package = p;
        this._package.validate();
        this._package.component = this;
        this._package.updateCallback = () => {
            this._propertyUpdated = true;
            this.validateAndSave();
        }
    }

    get package(): Package {
        return this._package;
    }

    assingPart() {
        this.assignPartNumber.emit(this.package);
    }

    get part_number(): PartNumber {
        return this.package.part_number;
    }

    get canEdit(): boolean {
    	return (super.canEdit && !this.package.haveShipment);
    }
  
}
