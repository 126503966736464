import { Component, OnInit, NgZone, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { CodebarReaderService, Package, AuthenticationService, CatalogsState, NotificationService, Client, PartNumber, Catalog, ContainerType, Pallet } from 'wms-lib';
import { Store } from '@ngxs/store';
import { PackingListComponent } from '../../../shared/components/base-components/packing-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDialogService } from 'wms-lib';
import * as _ from 'lodash';
import * as actions from 'wms-lib';

@Component({
  selector: 'tr[app-arrive-packing-list]',
  templateUrl: './arrive-packing-list.component.html',
  styleUrls: ['../../../shared/components/base-components/packing-list.component.scss']
})
export class ArrivePackingListComponent extends PackingListComponent implements OnInit {
  @Input() table: any;
  bundle_types: Map<number, string> = new Map([]);
  commodities: Map<number, string> = new Map([]);
  part_numbers: PartNumber[] = [];
  @Output() assignPartNumber: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected store:Store,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected codebar_service: CodebarReaderService,
              protected ng_zone: NgZone,
              protected modalDialogService: ModalDialogService,
              protected authenticationService : AuthenticationService,
              protected notificationService: NotificationService,
              elementRef: ElementRef
             ) {
    super(store,router,activatedRoute,codebar_service,ng_zone,modalDialogService,authenticationService,notificationService);
    this.el = elementRef;
  }

  ngOnInit() {
    super.ngOnInit();
    this.pl.updateCallback = () => this.save();

    this.addSub(this.store.select(CatalogsState.getMapCatalog('bundle_types')).
      subscribe((c: Map<number, string>) => {
        this.bundle_types = c;
      }));
    this.addSub(this.store.select(CatalogsState.getMapCatalog('commodities')).
      subscribe((c: Map<number, string>) => {
        this.commodities = c;
      }));
  }

  get selectedPackagesIds(): number[] {
    return _(this.pl.packages).values().
      map((p: Package) => p && p.component && p.component.selected ? p.id : null).
      compact().value();
  }

  protected setExpanded(value: boolean) {
    if (this._expanded != undefined && this._expanded != value)
      this.table.toggleRow(this.pl);
    super.setExpanded(value);
  }

  search(event: any) {
    if (this.pl.client.part_number){
      this.addSub(this.store.select(CatalogsState.getPartNumbersByClient(this.pl.client.id)).subscribe((data: {[part_id: string]: PartNumber}) => {
        this.part_numbers = _(data).
          values().
          filter((pn: PartNumber) => pn.search_tag.includes(event.query.toLowerCase()) ).
          value();
      }));
    } else
      this.part_numbers = [];
  }

  assingPart() {
    this.assignPartNumber.emit(this.pl);
  }

  showParts(panel: any, event: any) {
    if (this.pl.havePartNumbers)
      panel.show(event);
  }

  move(trk: string) {
    this.store.dispatch(new actions.MoveArrivalPackingsAction({ to: this.pl.id, trk: trk }));
  }

  promtClient() {
  	  if( !this.canEdit ) return;
  	  super.promtClient();
  }

  move_to_this() {
  	  if( !this.canEdit ) return;
  	  super.move_to_this();
  }

  get canEdit(): boolean {
	  return super.canEdit && ( !this.pl.haveShipment && !this.pl.somePackageHaveShipment );
  }

}
