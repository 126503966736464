import {  Directive,  Input, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { StateListenerComponent } from "../../../../shared/components/scan-listener/state-listener.component";
import { Subscription } from "rxjs";
import { Catalog, Client, GetTicketsAction, Ticket, TicketPagedList, AuthenticationService } from "wms-lib";
import * as _ from 'lodash';

@Directive({selector: 'appTicketListCards'})
export class TicketListCardsBaseComponent extends StateListenerComponent implements OnInit {
    @Input() public status: Catalog;
    ticket_paged_list: TicketPagedList;
    tickets: {[id:number]: Ticket};
    page: {[client:number]: {page: number, all_loaded: boolean}} = {};
    protected clear_loaded_pages: boolean;
    private tickets_subscriptions: {[name: string]: Subscription} = {};
    private _current_client: Client;

    constructor(protected store: Store, protected authenticationService: AuthenticationService) {
        super();
    }

    ngOnInit() {
        this.addSub(this.store.select(state => state.catalogsState.active_client).subscribe(client => {
            this._current_client = client;
            this.closeClientSubscriptionOnChange();
            this.setTicketsSubscription();
            this.loadFirstPageOfClientData(this.status.key);
        }));
    }


    get client_id(): number {
        return (this._current_client && this._current_client.id ) || -1
    }

    get canDelete(): boolean {
        return this.authenticationService.currentUserValue.isSupervisor;
    }

    cardTitle(ticket: Ticket):String {
        return `#${ticket.id} ${ticket.title}`;
    }

    cardDescription(ticket: Ticket): String {
        return ticket.description;
    }

    cardCreatedBy(ticket: Ticket): string {
        return `Created by: ${ticket.created_by ? ticket.created_by.username: ''}`;
    }

    cardAssignTo(ticket: Ticket): string {
        return `Assign to: ${ticket.assign_to ? ticket.assign_to.username: ''}`;
    }

    cardFullAuthors(ticket: Ticket): string {
        return this.cardCreatedBy(ticket) + '  ' + this.cardAssignTo(ticket);
    }

    protected disablePaging(enabled: boolean) {}

    private closeClientSubscriptionOnChange() {
        _.keys(this.tickets_subscriptions).forEach((name: string) => {
            if (name !== `${this.client_id}${this.status.key}`){
                this.tickets_subscriptions[name].unsubscribe();
                delete this.tickets_subscriptions[name];
            }
        });
    }

    private setTicketsSubscription() {
        this.tickets_subscriptions[`${this.client_id}${this.status.key}`] = this.store.select(state => state.helpDeskState.tickets[this.client_id])
            .subscribe(tickets => {
                if (tickets && tickets[this.status.key]){
                    this.ticket_paged_list = tickets[this.status.key];
                    this.tickets = this.ticket_paged_list.items;
                    if (this.page[this.client_id])
                        this.page[this.client_id].all_loaded = this.ticket_paged_list.all_loaded
                    this.disablePaging(this.ticket_paged_list.all_loaded);
                }
            });
        this.addSub(this.tickets_subscriptions[`${this.client_id}${this.status.key}`]);
    }

    private loadFirstPageOfClientData(status: string) {
        if (this.page[this.client_id] === undefined || this.page[this.client_id] === null) {
            this.page[this.client_id] = {all_loaded : false, page: 0 };
            this.requestTicketsByClient(this.client_id,status);
        }
    }


    protected requestTicketsByClient(client_id: number, status_key: string, page?: number) {
        this.page[client_id].page = page || this.page[client_id].page + 1;
        return this.store.dispatch(new GetTicketsAction({
            status_key,
            client_id,
            page: this.page[client_id].page,
            clear_loaded_pages: this.clear_loaded_pages
        }));
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
